import React from "react";
import plusWhiteIcon from "../../assets/plus-icon-white.svg"
import plusBlackIcon from "../../assets/plus-button-black.svg"
import minusWhiteIcon from "../../assets/minus-icon-white.svg"
import minusBlackIcon from "../../assets/minus-icon-black.svg"

import styles from "./style.module.sass"


interface IProps {
    onClick: (e: React.MouseEvent) => void;
    disabled?: boolean,
    size?: "small" | "default"
}

export const PlusButton = (props: IProps) => {
    const size = props.size || "default";

    let classes = [styles.plusButton];

    if(size === 'small'){
        classes.push(styles.small);
    }
    return <div className={classes.join(" ")} onClick={props.onClick}>
        <img src={plusBlackIcon} alt=""/>
    </div>
};

export const PlusPrimaryPinkButton = (props: IProps) => {
    return <div className={styles.plusPrimaryPinkButton} onClick={props.onClick}>
        <img src={plusWhiteIcon} alt=""/>
    </div>
};

export const PlusGreyButton = (props: IProps) => {
    return <div className={styles.plusPrimaryGreyButton} onClick={props.onClick}>
        <img src={plusBlackIcon} alt=""/>
    </div>
};


export const MinusButton = (props: IProps) => {
    const size = props.size || "default";

    let classes = [styles.plusButton];

    if(size === 'small'){
        classes.push(styles.small);
    }
    return <div className={classes.join(" ")} onClick={props.onClick}>
        <img src={minusBlackIcon} alt=""/>
    </div>
};


export const MinusPrimaryButton = (props: IProps) => {
    return <div className={styles.plusButton} onClick={props.onClick}>
        <img src={minusBlackIcon} alt=""/>
    </div>
};

export const PrimaryButton = (props: IProps & {title: string | any}) => {
    const classes = [styles.primaryButton];
    if(props.disabled){
        classes.push(styles.disabled)
    }
    return <div onClick={props.onClick} className={classes.join(" ")}>
        {props.title}
    </div>
};
