import style from "./style.module.sass"
import {useStores} from "../../../../hooks";
import {observer} from "mobx-react";
import PriceComponent from "../../../../components/priceComponent";
import * as React from "react";
import {FlexRowSpaceBetween} from "../../../../components/containers/containers";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {MenuType} from "../../../../services/menu/models";
import {useEffect} from "react";

const ToCheckoutButton = () => {
    const {cartStore, menuStore, generalMenuStore, generalStore} = useStores();
    const history = useHistory();
    const {t} = useTranslation(['general', 'menu'])


    const isOpen = !cartStore.isCartEmpty(MenuType.regular) && cartStore.menuId === menuStore.menu.id;

    if(!isOpen) return null;
    const price = cartStore.getTotal(MenuType.regular);


    return <div className={style.toCheckoutContainer}>
        <div className={style.button} onClick={() => {
            history.push("/cart")
            window.scrollTo(0, 0);
        }}>
            <FlexRowSpaceBetween>
                <span className={style.text}>
                    {generalStore.viewOnly ? t('menu:confirmOrderViewOnly') : t('menu:confirmOrder')}
                </span>
                <PriceComponent item={{
                    currencyId: "RUB",
                    price: price
                }}/>
            </FlexRowSpaceBetween>
        </div>
    </div>
};

export default observer(ToCheckoutButton);
