import {observer} from "mobx-react";
import React from "react"
import styles from "../components/style.module.sass"
import {FlexRowSpaceBetween} from "../../../../../components/containers/containers";
import PriceComponent from "../../../../../components/priceComponent";
import {PlusPrimaryPinkButton} from "../../../../../components/buttons/plusButton";
import {useStores} from "../../../../../hooks";
import {BadgeType, MediaType, MenuItem, MenuType} from "../../../../../services/menu/models";
import noImage from "./assets/noImageProduct.png";
import {Trans, useTranslation} from "react-i18next";
import PlusMinusWidget from "../../../../../components/plusMinusWidget";
import {useHistory} from "react-router";
import NewBadgeOverPhoto from "../components/badges/NewBadgeOverPhoto";
import SpicyBadge from "../components/badges/SpicyBadge";
import VerySpicyBadge from "../components/badges/VerySpicyBadge";
import VegBadge from "../components/badges/VegBadge";
import {getBadges} from "../libs";
import LazyImage from "../../../../../components/imageLazyLoader";
import time from "../../header/assets/time.svg"
import HitBadgeOverPhoto from "../components/badges/HitBadgeOverPhoto";
import {EventType, NewEventName} from '../../../../../services/general/models';
import style from '../../../../bookingCart/style.module.sass';
import Icon3d from "../../../../../assets/3d.svg";
import newOrderIcon from '../../../../../assets/new-order.svg';

interface IProps {
    item: MenuItem,
}

const MenuItemComponent = ({ item }: IProps) => {
    const { cartStore, menuStore, generalStore } = useStores();
    const isInCart = cartStore.isInCart(item.id, MenuType.regular);
    const { i18n, t } = useTranslation();
    const locale = i18n.language;
    const history = useHistory();
    const badges = getBadges(item);
    const menuCategory = menuStore.categories.find(category => category.id === item.data.menuCategoryId);


    const onPlusClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (item.data.menuItemModifierSets.length === 0) {
            generalStore.addNewEvent({ metainfo: null, name: NewEventName.addToCart, type: EventType.conversion })
            cartStore.addItem(item, [], 1);
        } else {
           /* menuStore.openModifiersPopup(item, () => {
                menuStore.closeModifiersPopup();
                history.goBack();
            });*/
            if (generalStore.alias) {
                history.push(`/${generalStore.alias}/category/${item.data.menuCategoryId}/item/${item.id}`);
            } else {
                history.push(`/menu/category/${item.data.menuCategoryId}/item/${item.id}?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}`)
            }
        }
    }

    const showModalWindow = () => {
        if (generalStore.alias) {
            history.push(`/${generalStore.alias}/category/${item.data.menuCategoryId}/item/${item.id}`)
        } else {
            history.push(`/menu/category/${item.data.menuCategoryId}/item/${item.id}?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}`)
        }
        /*menuStore.openModifiersPopup(item, () => {
            menuStore.closeModifiersPopup();
            history.goBack();
        });*/
    };


    let price: number;
    if (item.data.sellPrice.price === 0) {
        price = menuStore.getMinPrice(item);
    } else {
        price = item.data.sellPrice.price;
    }


    let classes = [styles.badgeWithTime];
    if(badges[BadgeType.spicy].isEnabled || badges[BadgeType.verySpicy].isEnabled || badges[BadgeType.vegetarian].isEnabled){
        classes.push(styles.withBadges);
    }

    return <div className={styles.menuItemContainer} onClick={showModalWindow}>
        <div className={styles.imgContainer}>
            {item.data.media.length > 0 &&
                <div>
					<LazyImage
                        type={MediaType.preview}
						media={item.data.media[0]}
						alt=""
					/>

                    <div className={styles.newBadgeImg}>
                        {badges[BadgeType.new].isEnabled &&
                            <NewBadgeOverPhoto/>
                        }
                        {badges[BadgeType.hit].isEnabled &&
                            <HitBadgeOverPhoto/>
                        }
                    </div>
                </div>
            }
            {item.data.media.length === 0 &&
            <div>
                <LazyImage
                    src={noImage} alt=""
                    media={null}
                />
                <div className={styles.newBadgeImg}>
                    {badges[BadgeType.new].isEnabled &&
                        <NewBadgeOverPhoto/>
                    }
                    {badges[BadgeType.hit].isEnabled &&
                        <HitBadgeOverPhoto/>
                    }
                </div>
            </div>
            }

        </div>
        <div className={styles.content}>
            <div className={classes.join(" ")}>

                <div>
                {
                    badges[BadgeType.spicy].isEnabled &&
                    <SpicyBadge/>
                }
                {
                    badges[BadgeType.verySpicy].isEnabled &&
                    <VerySpicyBadge/>
                }
                {
                    badges[BadgeType.vegetarian].isEnabled &&
                    <VegBadge/>
                }
                </div>
                {
                    (item.data.cookingTimeFromCategory && menuCategory && menuCategory.data.categoryCookingTime.fromTime) &&
                    <div className={styles.cookingTime}>
                        <img src={time} alt=""/>
                        <Trans i18nKey="menu:min" values={{
                            from: menuCategory.data.categoryCookingTime.fromTime,
                            to: menuCategory.data.categoryCookingTime.toTime
                        }}/>
                    </div>
                }
                {
                    (!item.data.cookingTimeFromCategory && item.data.itemCookingTime.fromTime) &&
                    <div className={styles.cookingTime}>
                        <img src={time} alt=""/>
                        <Trans i18nKey="menu:min" values={{
                            from: item.data.itemCookingTime.fromTime,
                            to: item.data.itemCookingTime.toTime
                        }}/>
                    </div>
                }
            </div>

            <p className={styles.title}>{item.data.name[locale]}</p>
            <p className={styles.description}>
                {item.data.description[locale]}
            </p>
        </div>
        {/*{item.data.arCodeLink && item.data.arCodeImage && (*/}
        {/*    <div style={{*/}
        {/*        padding: "0 5px",*/}
        {/*        marginTop: 5,*/}
        {/*        bottom: 5,*/}
        {/*        minWidth: "100%",*/}
        {/*    }}>*/}
        {/*        <div*/}
        {/*            style={{*/}
        {/*                display: 'flex',*/}
        {/*                flexDirection: 'row',*/}
        {/*                justifyContent: 'flex-end',*/}
        {/*                paddingRight: '10px'*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            <a href={item.data.arCodeLink} onClick={(event) => {*/}
        {/*                event.stopPropagation()*/}
        {/*            }}>*/}
        {/*                <img height={70} width={70} src={item.data.arCodeImage} alt="AR QR Code" />*/}
        {/*            </a>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*)}*/}
        {item.data.arCodeLink && (
            <div style={{
                bottom: 5,
                marginBottom: 10,
                minWidth: "100%",
            }}>
                <div
                    style={{
                        maxWidth: "200px",
                        width: "100%",
                        background: "rgba(247, 247, 247, 0.1)",
                        // background: "background: linear-gradient(90deg, #ff48b6 0%, #ffb660 51.84%, #ffea9f 100%);",
                        backdropFilter: "blur(2px)",
                    }}
                    onClick={(event) => {
                        window.open(item.data.arCodeLink || "")
                        event.stopPropagation()
                    }}
                >
                    <div
                        className={style.sbpButton}
                        style={{
                            // background: "rgb(21, 14, 51)",
                            background: "linear-gradient(90deg, #ff48b6 0%, #ffb660 51.84%, #FFDE69 100%)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            textAlign: "center",
                            // fontWeight: 500,
                            borderRadius: 10,
                            color: "#ffffff",
                            padding: "10px 10px",
                            margin: "0 5px"
                        }}
                    >
                        <img src={Icon3d} alt=''/>
                        <div style={{fontWeight: 500, fontSize: '11pt'}}>
                            Доступно в
                        </div>
                        <div style={{fontWeight: 700, fontSize: '16pt'}}>
                            3D
                        </div>
                    </div>
                </div>
            </div>
        )}
        <div style={{
            padding: "0 5px",
            marginTop: 5,
            position: "absolute",
            bottom: 5,
            minWidth: "100%",
            boxSizing: "border-box"
        }}>
            {!isInCart && item.data.oldPrice && item.data.oldPrice.price > 0 && <FlexRowSpaceBetween>
                <PriceComponent crossed={true} item={item.data.oldPrice}/>
            </FlexRowSpaceBetween>}
            {
                !isInCart ? <FlexRowSpaceBetween>
                        <PriceComponent item={{
                            price: price,
                            currencyId: item.data.sellPrice.currencyId
                        }}
                        />
                        <PlusPrimaryPinkButton onClick={onPlusClick}/>
                    </FlexRowSpaceBetween>
                    :
                    <div>
                        <p className={styles.priceHint}>
                            {(price / 100).toFixed(price % 100 > 0 ? 2 : 0)} {t("general:currency.rub")}
                        </p>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <PlusMinusWidget menuItem={item} index={0} onCartPage={false}/>
                        </div>
                    </div>
            }

        </div>
    </div>
};

export default observer(MenuItemComponent);
