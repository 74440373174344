import {rootStore} from "../../store";
import {BookingOrder, Order, PaymentProviderType} from "../../services/order/models";
import {useTranslation} from "react-i18next";
import {MenuType} from "../../services/menu/models";


interface IParams {
    onSuccess: () => void;
    onFail: () => void;
    onComplete?: () => void;
}

const useCloudPayments = () => {
    const paymentProviderDetails = rootStore.generalStore.paymentProviderDetails[PaymentProviderType.cloudpayments];
    const {i18n, t} = useTranslation(["general", "cart"]);
    const locale = i18n.language;

    if (!paymentProviderDetails || paymentProviderDetails.type !== PaymentProviderType.cloudpayments) {
        return {
            initCharge: (params: {onSuccess: () => void; onFail: () => void}) => {
                rootStore.checkoutStore.reloadOrder();
                rootStore.bookingCheckoutStore.reloadOrder()
                params.onSuccess();
            }
        }
    }

    const initCharge = (params: IParams, order: Order | BookingOrder) => {

        if (order && order.totalPrice.price === 0) {
            params.onSuccess();
            return;
        }
        const options = {
            language:  locale === 'ru' ? "ru-RU" : "en-US"
        }
        //@ts-ignore
        const widget = new cp.CloudPayments(options);
        widget.pay('auth', // или 'auth'
            { //options
                publicId: paymentProviderDetails.publicId,
                description: t("cart:payments.description") + `#${order.id}`,
                amount: order.totalPrice.price / 100, //сумма
                currency: 'RUB', //валюта
                invoiceId: order.id.toString(), //номер заказа  (необязательно)
                accountId: order.clientSessionId, //идентификатор плательщика (необязательно)
                skin: "mini", //дизайн виджета (необязательно)
                data: {
                    myProp: 'myProp value'
                }
            },
            {
                onSuccess: params.onSuccess,
                onFail: params.onFail,
                onComplete: function (paymentResult: any, options: any) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                    setTimeout(() => {
                        rootStore.checkoutStore.reloadOrder();
                        rootStore.bookingCheckoutStore.reloadOrder()
                    }, 2000);
                    //например вызов вашей аналитики Facebook Pixel
                    console.log(options, paymentResult);
                }
            }
        )
    };
    return { initCharge }
};


export default useCloudPayments;
