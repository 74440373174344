import { useStores } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import style from "../style.module.sass";
import noImageCategory from "./../../menuItems/menuItemsWithPhoto/assets/noImageProduct.png"
import CloseButton from "../../../../../components/buttons/closeButton";
import { MediaItem, MediaType } from "../../../../../services/menu/models";
import LazyImage from "../../../../../components/imageLazyLoader";


const CategoriesBurgerMenu = () => {

    const { menuStore, generalMenuStore, generalStore } = useStores();
    const { t, i18n } = useTranslation(['menu']);
    const isOpen = generalMenuStore.isCategoryMenuVisible;

    const classes = [style.burgerMenu];
    if (isOpen) {
        classes.push(style.open);
    }


    const closeCategoryMenu = () => {
        generalMenuStore.isCategoryMenuVisible = false;
        generalMenuStore.isOverlayOpen = false;
        generalMenuStore.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    };
    const retina = window.devicePixelRatio > 1;


    return (
        <div className={classes.join(" ")}>
            <h3> {t('menu:categories')}</h3>

            <div className={style.burgerMenuCategoriesContainer}>
                {menuStore.categories.map((item) => {

                    const categorizedItems = menuStore.items[item.id.toString()]
                    let media: MediaItem | null = null;
                    for (let item of categorizedItems){
                        if (item.data?.media.length > 0) {
                            media = item.data.media[0];
                            break
                        }
                    }

                    let text = "";
                    if (generalStore.locale.id === "ru" ) {
                        if (categorizedItems.length % 10 === 1 && (categorizedItems.length < 10 || categorizedItems.length > 21)) text += "ия"
                        else if ((categorizedItems.length % 10 === 2 ||
                            categorizedItems.length % 10 === 3 ||
                            categorizedItems.length % 10 === 4) && (categorizedItems.length < 10 || categorizedItems.length > 21)) text+= "ии"
                        else if (categorizedItems.length > 10 && categorizedItems.length < 21) text+= "ий"
                        else text+= "ий"
                    }

                    return <div
                        key={item.id}
                        className={style.category}
                        onClick={() => {
                            generalMenuStore.closeCategoryMenu()
                            menuStore.setActiveCategory(item.id, true);
                        }}
                    >

                        <div className={style.imageCategory}>
                            {media === null ?
                                <img src={noImageCategory} alt=""/>
                                :
                                <LazyImage media={media} alt={""} type={MediaType.preview} height={'auto'} disableLazy={true}/>
                            }

                        </div>
                        <div className={style.categoryName}>
                            <h4>{item.data.name[i18n.language]}</h4>
                            <p>
                                <Trans i18nKey="menu:categoryItem" values={
                                    { amount: `${categorizedItems.length}` }
                                }/>
                                {text}
                            </p>
                        </div>
                        <svg className={style.arrowIcon} xmlns="http://www.w3.org/2000/svg" width="43.837" height="83.159"
                             viewBox="0 0 43.837 83.159">
                            <g id="Group_94" data-name="Group 94" transform="translate(-410.63 -74.425)">
                                <g id="Group_93" data-name="Group 93" transform="translate(116 6)">
                                    <rect id="Rectangle_113" data-name="Rectangle 113" width="11.014" height="55.072"
                                          rx="5.507" transform="matrix(-0.766, 0.643, -0.643, -0.766, 338.467, 144.504)"
                                          fill="#dadada"/>
                                    <rect id="Rectangle_114" data-name="Rectangle 114" width="11.015" height="55.072"
                                          rx="5.507" transform="translate(330.03 68.425) rotate(40)" fill="#dadada"/>
                                </g>
                            </g>
                        </svg>

                    </div>
                })}
            </div>

            <div style={{
                padding: 10
            }}>
                <CloseButton
                    onClick={closeCategoryMenu}
                    classes={[]}
                />
            </div>
        </div>
    )
}

export default observer(CategoriesBurgerMenu);
