import React, { MutableRefObject } from "react";

import styled, { keyframes } from "styled-components";

import LazyLoad from "react-lazyload";
import { MediaItem, MediaType } from "../../services/menu/models";

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${(props: {height?: number | string}) => props.height || 'calc(50vw - 15px)'};
  max-height: 200px;
  @media (min-width: 700px) and (max-width: 895px) {
      height: ${(props: {height?: number | string}) => props.height || 'calc(24vw - 15px)'};
    };
  @media (min-width: 896px) and (max-width: 1199px) {
    height: ${(props: {height?: number | string}) => props.height || 'calc(24vw - 15px)'};
    max-height: 300px;
  };
  @media (min-width: 1200px) {
    height: ${(props: {height?: number | string}) => props.height || 'calc(16vw - 15px)'};
    max-height: 277px;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #eeeeee;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 2s infinite;
`;

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface ImageProps{
    media: null
    src: string,
    alt: string,
    height?: number | string,
    disableLazy?: boolean
}
interface MediaProps{
    media: MediaItem,
    alt: string,
    type: MediaType,
    height?: number | string,
    disableLazy?: boolean
}




type Props = MediaProps | ImageProps;

const LazyImage = (props: Props) => {
    const retina = window.devicePixelRatio > 1;

    let url = '';
    if(props.media !== null){
        if(props.type === MediaType.preview){
            if(retina && props.media.previewHighRes){
                url = props.media.previewHighRes;
            }else if(!retina && props.media.preview){
                url = props.media.preview
            }else{
                url = props.media.source;
            }
        }else if(props.type === MediaType.regular){
            if(retina && props.media.regularHighRes){
                url = props.media.regularHighRes;
            }else if(!retina && props.media.regular){
                url = props.media.regular
            }else{
                url = props.media.source;
            }
        }
    }else{
        url = props.src
    }
    const refPlaceholder = React.useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>

    const removePlaceholder = () => {
        refPlaceholder.current?.remove();
    };

    if(props.disableLazy){
        return <img src={url} alt="" style={{width: '100%'}}/>
    }

    return (
        <ImageWrapper height={props.height}>
            <LazyLoad offset={500}>
                <StyledImage
                    onLoad={removePlaceholder}
                    onError={removePlaceholder}
                    src={url}
                    alt={props.alt}
                />
            </LazyLoad>
            <Placeholder ref={refPlaceholder} />
        </ImageWrapper>
    );
};


export default LazyImage;


