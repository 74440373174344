import {observer} from "mobx-react";
import React from "react"
import styles from "../components/style.module.sass"
import {FlexRowSpaceBetween} from "../../../../../components/containers/containers";
import PriceComponent from "../../../../../components/priceComponent";
import {PlusPrimaryPinkButton} from "../../../../../components/buttons/plusButton";
import {useStores} from "../../../../../hooks";
import {BookingBadgeType, MediaType, MenuBookingItem, MenuType} from "../../../../../services/menu/models";
import noImage from "./assets/noImageProduct.png";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import NewBadgeOverPhoto from "../components/badges/NewBadgeOverPhoto";
import {getBookingBadges} from "../libs";
import LazyImage from "../../../../../components/imageLazyLoader";
import HitBadgeOverPhoto from "../components/badges/HitBadgeOverPhoto";
import PlusMinusWidget from "../../../../../components/plusMinusWidget";
import ProfitableBadge from "../components/badges/ProfitableBadge";
import ProfitableBadgeOverPhoto from "../components/badges/ProfitableBadgeOverPhoto";
import moment from "moment-timezone";


interface IProps {
    item: MenuBookingItem,
}

const MenuItemComponent = ({ item }: IProps) => {
    const { cartStore, bookingMenuStore, generalStore, bookingCheckoutStore } = useStores();
    const isInCart = cartStore.isInCart(item.id, MenuType.booking);
    const { i18n, t } = useTranslation();
    const locale = i18n.language;
    const history = useHistory();
    const badges = getBookingBadges(item);
    const currentMenu = bookingMenuStore.bookingMenu;


    const onPlusClick = (e: React.MouseEvent) => {
        e.stopPropagation();
           /* menuStore.openModifiersPopup(item, () => {
                menuStore.closeModifiersPopup();
                history.goBack();
            });*/
            if (generalStore.alias) {
                history.push(`/${generalStore.alias}/category/${item.data.menuBookingCategoryId}/item/${item.id}`);
                bookingCheckoutStore.getTimeSlots(moment(), item.id);
            } else {
                history.push(`/menu/category/${item.data.menuBookingCategoryId}/item/${item.id}?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}`)
                bookingCheckoutStore.getTimeSlots(moment(), item.id);
            }
    }

    const showModalWindow = async () => {
        if (generalStore.alias) {
            history.push(`/${generalStore.alias}/category/${item.data.menuBookingCategoryId}/item/${item.id}`)
            if (currentMenu) {
                const getDateWithTimeZone = new Date().getTime() + currentMenu.data.workingSchedule.timezoneInMinutes*60000;
                await bookingCheckoutStore.getTimeSlots(moment(getDateWithTimeZone), item.id);
            }
        } else {
            history.push(`/menu/category/${item.data.menuBookingCategoryId}/item/${item.id}?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}`);
            if (currentMenu) {
                const getDateWithTimeZone = new Date().getTime() + currentMenu.data.workingSchedule.timezoneInMinutes*60000;
                await bookingCheckoutStore.getTimeSlots(moment(getDateWithTimeZone), item.id);
            }
        }
        /*menuStore.openModifiersPopup(item, () => {
            menuStore.closeModifiersPopup();
            history.goBack();
        });*/
    };


    let price: number;
    if (item.data.sellPrice.price === 0) {
        price = bookingMenuStore.getMinPrice(item);
    } else {
        price = item.data.sellPrice.price;
    }


    return <div className={styles.menuItemContainer} onClick={showModalWindow}>
        <div className={styles.imgContainer}>
            {item.data.media.length > 0 &&
                <div>
                    <LazyImage
                        type={MediaType.preview}
                        media={item.data.media[0]}
                        alt=""
                    />
                    <div className={styles.newBadgeImg}>
                            {badges[BookingBadgeType.new].isEnabled &&
                                <NewBadgeOverPhoto/>
                            }
                            {badges[BookingBadgeType.hit].isEnabled &&
                                <HitBadgeOverPhoto/>
                            }
                            {badges[BookingBadgeType.profitable].isEnabled &&
                                <ProfitableBadgeOverPhoto/>
                            }
                    </div>
                </div>
            }
            {item.data.media.length == 0 &&
            <div>
                <LazyImage
                    src={noImage} alt=""
                    media={null}
                />
                <div className={styles.newBadgeImg}>
                    <div className={styles.badgesOverPhoto}>
                        {badges[BookingBadgeType.new].isEnabled &&
                            <NewBadgeOverPhoto/>
                        }
                        {badges[BookingBadgeType.hit].isEnabled &&
                            <HitBadgeOverPhoto/>
                        }
                        {badges[BookingBadgeType.profitable].isEnabled &&
                            <ProfitableBadgeOverPhoto/>
                        }
                    </div>
                </div>
            </div>
            }

        </div>
        <div className={styles.content}>

            <p className={styles.title}>{item.data.name[locale]}</p>
            <p className={styles.description}>
                {item.data.description[locale]}
            </p>
        </div>
        <div style={{
            padding: "0 5px",
            marginTop: 5,
            position: "absolute",
            bottom: 5,
            minWidth: "100%",
            boxSizing: "border-box"
        }}>
            {/* <FlexRowSpaceBetween>
                <PriceComponent item={item.data.sellPrice}/>
                <PlusPrimaryPinkButton onClick={onPlusClick}/>
            </FlexRowSpaceBetween>*/}
            {
                !isInCart ? <FlexRowSpaceBetween>
                        <PriceComponent item={{
                            price: price,
                            currencyId: item.data.sellPrice.currencyId
                        }} from={true}
                        />
                        <PlusPrimaryPinkButton onClick={onPlusClick}/>
                    </FlexRowSpaceBetween>
                    :
                    <div>
                        <p className={styles.priceHint}>
                            {(price / 100).toFixed(0)} {t("general:currency.rub")}
                        </p>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <PlusMinusWidget menuItem={item} index={0} onCartPage={false}/>
                        </div>
                    </div>
            }

        </div>
    </div>
};

export default observer(MenuItemComponent);
