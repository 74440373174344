import {PaymentProviderType, YandexDeliveryPerformerInfo, DeliveryPriceOffer, ClaimStatus} from "../order/models";
import { LocalizedString } from "../general/models";

type Currency = "USD" | "RUB";

export interface PriceInCurrency {
    currencyId: Currency;
    price: number;
}

export enum DeliveryType {
    toTable = 'ToRestaurantTable',
    toAddress = 'ToAddress',
    toAddressByYandexDelivery = 'ToAddressByYandexDelivery',
    takeaway = 'TakeAway',
    takeawayToTime = 'TakeAwayToTime',
    toVehicle = 'ToVehicle',
    insideTheRestaurant = 'InsideTheRestaurant'
}

export enum BadgeType {
    new = 'New',
    spicy = 'Spicy',
    verySpicy = 'VerySpicy',
    vegetarian = 'Vegetarian',
    hit = 'Hit'
}

export interface DeliveryToTableDetails {
    type: DeliveryType.toTable,
    tableId: number,
    seatId: string | null
}
export interface DeliveryToAddressDetails {
    type: DeliveryType.toAddress,
    address: string;
    streetType: string | null;
    street: string | null;
    houseType: string | null;
    house: string | null;
    buildingType: string | null;
    building: string | null;
    comment: string | null;
    coordinates: Coordinates | null;
    deliveryDetailsData: DeliveryDetailsData | null;
    flat: number | null;
    floor: number | null;
    name: string | null;
    orderDeliveryPriceOfferId: number | null;
    phoneNumber: string;
    porch: string | null;
}
export interface DeliveryDetailsToAddressByYandexDelivery {
    type: DeliveryType.toAddressByYandexDelivery,
    address: string;
    streetType: string | null;
    street: string | null;
    houseType: string | null;
    house: string | null;
    buildingType: string | null;
    building: string | null;
    comment: string | null;
    coordinates: Coordinates | null;
    deliveryDetailsData: DeliveryDetailsData | null;
    flat: number | null;
    floor: number | null;
    name: string | null;
    orderDeliveryPriceOfferId: number | null;
    phoneNumber: string;
    porch: string | null;
}

export interface DeliveryToAddressByYandexDeliveryDetails {
    type: DeliveryType.toAddressByYandexDelivery,
    address: string,
    phoneNumber: string
}

export interface DeliveryTakeAwayDetails {
    type: DeliveryType.takeaway,
    phoneNumber: string;
    name: string | null;
}

export interface DeliveryDetailsTakeAwayToTime {
    type: DeliveryType.takeawayToTime,
    name: string | null;
    phoneNumber: string;
    toHour: number | null;
    toMinute: number | null;
    date: Date | null;
}

export interface DeliveryToVehicleDetails {
    type: DeliveryType.toVehicle,
    phoneNumber: string;
    vehicleBrandName: string,
    vehicleColor: string,
    vehiclePlateNumber: string,
}
export interface DeliveryInsideTheRestaurantDetails {
    type: DeliveryType.insideTheRestaurant,
}
export type DeliveryDetails = DeliveryToTableDetails | DeliveryToAddressDetails | DeliveryDetailsToAddressByYandexDelivery |
    DeliveryTakeAwayDetails | DeliveryDetailsTakeAwayToTime | DeliveryToVehicleDetails | DeliveryInsideTheRestaurantDetails;

export interface Coordinates {
    latitude: number;
    longitude: number;
}
export interface DeliveryDetailsData {
    claimId: string | null;
    claimRequestIdVersion: number;
    performerInfo: YandexDeliveryPerformerInfo | null;
    priceOffer: DeliveryPriceOffer | null;
    status: ClaimStatus | null;
    version: number | null;
}

export enum DeliveryConditionType{
    fixedPrice = 'FixedPrice',
    dynamicPrice = 'DynamicPrice',
    zonePrice = "ZonePrice"
}

export interface IDeliveryConditionsFixedPrice{
    type: DeliveryConditionType.fixedPrice,
    fixedDeliveryPrice: PriceInCurrency,
    freeDeliveryMinimalOrderPrice: PriceInCurrency
}

export interface IDeliveryConditionsDynamicPrice{
    type: DeliveryConditionType.dynamicPrice,
    multiplier: number,
}

export interface IDeliveryConditionsZonePrice{
    type: DeliveryConditionType.zonePrice,
    deliveryZones: DeliveryZone[]
}
export interface DeliveryZone{
    name: string | null,
    freeDeliveryMinimalOrderPrice: PriceInCurrency | null,
    fixedDeliveryPrice: PriceInCurrency | null,
    coordinates: Coordinates[]
}


export interface Coordinates {
    latitude: number;
    longitude: number;
}

export type DeliveryConditions = IDeliveryConditionsDynamicPrice | IDeliveryConditionsFixedPrice | IDeliveryConditionsZonePrice;


interface MenuPhotos {
    backgroundPhotoUrl: string | null;
    logoPhotoUrl: string | null;
}

export interface IOfflinePaymentSettings{
    isEnabled: boolean,
    type: PaymentProviderType.offline
}

export interface ICloudPaymentsPaymentSettings{
    isEnabled: boolean,
    type: PaymentProviderType.cloudpayments
}
export interface ISberbankPaymentsPaymentSettings{
    isEnabled: boolean,
    type: PaymentProviderType.sberbank
}
export interface ISBPTochkaPaymentSettings{
    isEnabled: boolean,
    type: PaymentProviderType.sbpTochka
}

export interface ICompanyInvoiceSettings{
    isEnabled: boolean,
    type: PaymentProviderType.companyInvoice,
    nextInvoiceId: number
}

export interface IToBankCardPaymentSettings{
    isEnabled: boolean,
    type: PaymentProviderType.toBankCard,
    requisites: string,
    title: LocalizedString,
    description: LocalizedString,
    buttonTitle: LocalizedString,
    buttonUrl: string
}

export type PaymentSettings =  IOfflinePaymentSettings | ICloudPaymentsPaymentSettings
    | IToBankCardPaymentSettings | ICompanyInvoiceSettings | ISBPTochkaPaymentSettings


export interface AddressDetails {
    mapsUrl: string | null;
    title: { [key: string]: string } | null;
}

export interface ContactDetails {
    instagram: string | null;
    phoneNumber: string | null;
    telegram: string | null;
    websiteUrl: string | null;
    whatsApp: string | null;
}

export interface WorkingSchedule {
    friday: WorkingScheduleDay;
    monday: WorkingScheduleDay;
    saturday: WorkingScheduleDay;
    sunday: WorkingScheduleDay;
    thursday: WorkingScheduleDay;
    timezoneInMinutes: number;
    tuesday: WorkingScheduleDay;
    wednesday: WorkingScheduleDay;
}

export interface WorkingScheduleDay {
    isDayOff: boolean;
    slots: WorkingScheduleDaySlot[];
}
export interface WorkingScheduleDaySlot {
    fromHour: number;
    fromMinute: number;
    toHour: number;
    toMinute: number;
}

export interface Reporting {
    destinations: ReportingDestination[];
}

export interface ReportingFilter {
    type: string;
}

export interface ReportingDestination {
    filters: ReportingFilter[];
    type: string;
}

interface OrderCancellationSettings {
    autoCancelForgottenOrdersAfterSeconds: number;
}


export enum CategoryListPhotosMode{
    defaultWithPhotos = "defaultWithPhotos",
    defaultNoPhotos = "defaultNoPhotos",
}

export enum MenuItemsPhotosModes{
    defaultWithPhotos = "defaultWithPhotos",
    defaultNoPhotos = "defaultNoPhotos",
    verticalWithPhotos = "verticalWithPhotos",
}

export enum ThemesIds{
    default = "default",
    expensiveBlack = "expensiveBlack",
}

export enum MenuType {
    regular = "Regular",
    booking = "Booking",
    none = 'None'
}

export interface ThemeSettings {
    categoryListPhotosMode: CategoryListPhotosMode;
    storefrontPhotosMode: MenuItemsPhotosModes;
    themeId: ThemesIds;
}
export interface MenuData {
    addressDetails: AddressDetails;
    contactDetails: ContactDetails;
    workingSchedule: WorkingSchedule;
    name: { [key: string]: string };
    description: { [key: string]: string };
    isPublic: boolean;
    orderCancellationSettings: OrderCancellationSettings;
    reporting: Reporting;
    sortOrder: number;
    showPhotos: boolean;
    isPromoCodesEnabled: boolean;
    isCallWaiterEnabled: boolean;
    isMenuEnabled: boolean;
    deliverySettings:{
        [DeliveryType.toTable]:{
            isEnabled: boolean,
            type: DeliveryType.toTable,
            canChooseSeat: boolean,
            isChangeTableEnabled: boolean,
            description: LocalizedString
        },
        [DeliveryType.toAddress]:{
            isEnabled: boolean,
            type: DeliveryType.toAddress,
            deliveryConditions: DeliveryConditions,
            description: LocalizedString
        },
        [DeliveryType.toAddressByYandexDelivery]:{
            isEnabled: boolean,
            type: DeliveryType.toAddressByYandexDelivery,
            deliveryConditions: DeliveryConditions,
            description: LocalizedString
        },
        [DeliveryType.takeaway]:{
            isEnabled: boolean,
            type: DeliveryType.takeaway,
            description: LocalizedString
        },
        [DeliveryType.takeawayToTime]:{
            isEnabled: boolean,
            type: DeliveryType.takeawayToTime,
            description: LocalizedString,
            isDateRequired: boolean,
        },
        [DeliveryType.toVehicle]:{
            isEnabled: boolean,
            type: DeliveryType.toVehicle,
            description: LocalizedString
        },
        [DeliveryType.insideTheRestaurant]:{
            isEnabled: boolean,
            type: DeliveryType.insideTheRestaurant,
            description: LocalizedString
        }
    },
    paymentSettings:{
        [PaymentProviderType.cloudpayments]: ICloudPaymentsPaymentSettings,
        [PaymentProviderType.sberbank]: ISberbankPaymentsPaymentSettings,
        [PaymentProviderType.sbpTochka]: ISBPTochkaPaymentSettings,
        [PaymentProviderType.offline]: IOfflinePaymentSettings,
        [PaymentProviderType.toBankCard]: IToBankCardPaymentSettings,
        [PaymentProviderType.companyInvoice]: ICompanyInvoiceSettings
    }
    photos: MenuPhotos;
    themeSettings: ThemeSettings,
    isFeedbackEnabled: boolean;
    isOrderCreationEnabled: boolean;
    availableLanguages: MenuAvailableLanguages;
    isLoyaltyProgramEnabled: boolean;
    isLogoAndLinkEnabled: boolean;
}

export interface MenuAvailableLanguages {
    zh: MenuAvailableLanguageSettings;
    en: MenuAvailableLanguageSettings;
    it: MenuAvailableLanguageSettings;
    ru: MenuAvailableLanguageSettings;
}

export interface MenuAvailableLanguageSettings {
    isEnabled: boolean;
}

export interface Menu {
    data: MenuData;
    id: number;
    organizationBranchId: number;
    menuCategoriesQuantity: number,
    menuItemsQuantity: number,
    docs: {
        menuPublicOfferUrl: string | null
    },
    type: MenuType;
}


export interface MenuBookingCategory {
    data: MenuBookingCategoryData;
    externalId: string | null;
    id: number;
    isRemoved: boolean;
    menuId: number;
    organizationBranchId: number;
}

export interface MenuBookingCategoryData {
    isInStopList: boolean;
    name: { [key: string]: string };
    sortOrder: number;
}

export interface BookingItemBadge {
    isEnabled: boolean;
    type: string;
}

export type BookingScheduleSettings = WithinDayByStepBookingScheduleSettings;

export enum BookingScheduleSettingsType {
    withinDayByStep = "WithinDayByStep"
}

export interface WithinDayByStepBookingScheduleSettings {
    type: BookingScheduleSettingsType.withinDayByStep
    step: number;
    fromHour: number;
    fromMinute: number;
    toHour: number;
    toMinute: number;
}


export interface Pair<A, B> {
    first: A;
    second: B;
}


export interface MenuItemMedia {
    preview: string;
    previewHighRes: string;
    regular: string;
    regularHighRes: string;
    source: string;
}

export interface MenuBookingItemSuggestion {
    menuId: number;
    menuItemId: number;
    sortOrder: number;
}


export interface MenuBookingItem {
    data: MenuBookingItemData;
    externalId: string | null;
    id: number;
    isRemoved: boolean;
    menuId: number;
    organizationBranchId: number;
}

export interface MenuItemModifierSetDescriptor {
    menuItemModifierSetId: number;
    sortOrder: number;
}

export interface MenuInfoPublic{
    id: number,
    organizationBranchId: number,
    data: MenuData,
    type: MenuType
}


export interface MenuCategoryData {
    name: { [key: string]: string };
    parentMenuCategoryId: number | null;
    sortOrder: number;
    isInStopList: boolean;
    categoryCookingTime: CookingTime;
}

export interface CookingTime {
    fromTime: number | null,
    toTime: number | null
}

export interface MenuCategory {
    data: MenuCategoryData;
    id: number;
    menuId: number;
}

export interface MenuItemModifierDescriptor {
    menuItemModifierSetId: number;
    sortOrder: number;
}
export interface BadgeDetails{
    isEnabled: boolean;
    type: BadgeType;
}

export interface BadgesData{
    [BadgeType.new]?: BadgeDetails;
    [BadgeType.spicy]?: BadgeDetails;
    [BadgeType.verySpicy]?: BadgeDetails;
    [BadgeType.vegetarian]?: BadgeDetails;
    [BadgeType.hit]?: BadgeDetails;
}

export interface MenuItemData {
    badges: BadgesData;
    description: LocalizedString
    isInStopList: boolean;
    menuCategoryId: number;
    menuItemModifierSets: MenuItemModifierDescriptor[];
    name: LocalizedString,
    participatesInPromotions: boolean;
    photosUrls: string[];
    productId: number;
    sellPrice: PriceInCurrency;
    oldPrice?: PriceInCurrency;
    sortOrder: number;
    inputProductAmountNetto: number;
    cookingTimeFromCategory: boolean;
    itemCookingTime: CookingTime;
    media: MediaItem[];
    caloriesSettings: MenuItemCaloriesSettings;
    weightSettings: MenuItemWeightSettings;
    arCodeLink: string | null;
    arCodeImage: string | null;
}
export interface MediaItem {
    preview: string;
    previewHighRes: string;
    regular: string;
    regularHighRes: string;
    source: string;
}


export enum MediaType {
    source = "source",
    preview = "preview",
    regular = "regular",
}

export interface MenuItem {
    data: MenuItemData;
    id: number;
    menuId: number;
}

export interface MenuWithExtraData {
    isRemoved: boolean;
    data: MenuData;
    id: number;
    menuCategoriesQuantity: number;
    menuItemsQuantity: number;
    organizationBranchId: number;
    qrCodesURLs: QRCodesURL[];
    aliasesQuantity: number;
    ordersQuantity: number;
    type: MenuType;
}

export interface QRCodesURLData {
    menuAlias: string;
}

export interface QRCodesURL {
    data: QRCodesURLData;
    lastDownloadedAt: Date | null;
    url: string;
    urlGeneratedAt: Date;
}

export interface MenuItemModifierData {
    name: { [key: string]: string };
    photosUrls: string[];
    productId: number;
    sellPrice: PriceInCurrency;
    inputProductAmountNetto: number;
    outputProductAmountNetto: number | null;
    isInStopList: boolean
}

export interface MenuItemModifier {
    data: MenuItemModifierData;
    id: string
}

export interface MenuItemModifierSetData {
    modifiers: MenuItemModifier[];
    name: { [key: string]: string };
    type: ModifiersSetType;
}

export interface MenuItemModifierSetChooseOneData extends MenuItemModifierSetData {
    type: ModifiersSetType.ChooseOne
    isRequired: boolean
}

export interface MenuItemModifierSetChooseManyData extends MenuItemModifierSetData {
    type: ModifiersSetType.ChooseMany,
    maxCountTotalChosen: number,
    minCountTotalChosen: number
}


export interface MenuItemModifierSet {
    data: MenuItemModifierSetChooseOneData | MenuItemModifierSetChooseManyData;
    id: number;
    organizationBranchId: number;
}



export enum ModifiersSetType{
    ChooseMany = 'ChooseMany',
    ChooseOne = 'ChooseOne'
}


export interface TableSeat {
    publicId: string;
}

export interface TableData {
    publicId: number,
    title: {
        [s: string]: string
    },
    description: string,
    seats: TableSeat[];
    view: {
        position: {
            x: number,
            y: number
        },
        size: {
            width: number,
            height: number
        }
    }
}

export interface Table {
    id: number,
    data: TableData;
    isRemoved: boolean;
    organizationBranchId: number;
}

export interface MenuWithQuantitiesPublic extends MenuWithQuantities {
    data: MenuData;
    docs: OrganizationBranchDocs;
    id: number;
    menuCategoriesQuantity: number;
    menuItemsQuantity: number;
    organizationBranchId: number;
    type: MenuType;
}
export interface MenuWithQuantities extends Menu {
    data: MenuData;
    id: number;
    menuCategoriesQuantity: number;
    menuItemsQuantity: number;
    organizationBranchId: number;
    type: MenuType;
}

export interface OrganizationBranchDocs {
    menuPublicOfferUrl: string | null;
}

export interface MenuBookingCategoryData {
    isInStopList: boolean;
    name: { [key: string]: string };
    sortOrder: number;
}

export interface MenuBookingCategory {
    data: MenuBookingCategoryData;
    externalId: string | null;
    id: number;
    isRemoved: boolean;
    menuId: number;
    organizationBranchId: number;
}
export enum BookingBadgeType {
    new = 'New',
    profitable = 'Profitable',
    hit = 'Hit'
}

export interface MenuBookingItemData {
    amount: number;
    badges: BookingBadgesData;
    bookingScheduleSettings: BookingScheduleSettings;
    description: { [key: string]: string };
    isInStopList: boolean;
    media: MediaItems[];
    menuBookingCategoryId: number;
    menuBookingItemSuggestions: MenuBookingItemSuggestion[];
    menuItemModifierSets: MenuItemModifierSetDescriptor[];
    name: { [key: string]: string };
    sellPrice: PriceInCurrency;
    sortOrder: number;
}

export interface MediaItems {
    preview: string;
    previewHighRes: string;
    regular: string;
    regularHighRes: string;
    source: string;
    sourceId: string;
}

export interface BookingBadgesData{
    [BookingBadgeType.new]: BookingBadgeDetails;
    [BookingBadgeType.profitable]: BookingBadgeDetails;
    [BookingBadgeType.hit]: BookingBadgeDetails;
}

export interface BookingBadgeDetails{
    isEnabled: boolean;
    type: BookingBadgeType;
}

export interface MenuItemCaloriesSettings {
    calories: number | null;
    carbohydrates: number | null;
    fats: number | null;
    proteins: number | null;
}

export interface MenuItemWeightSettings {
    measurement: string | null;
    value: string | null;
}







