import BackButton from "../../components/buttons/backButton";
import style from "./style.module.sass"
import { useStores } from "../../hooks";
import {MediaType, MenuBookingItem, MenuItem, MenuItemsPhotosModes, MenuType} from "../../services/menu/models";
import usePayments from "../../hooks/payments/usePayments";
import { FadeInOutVertical } from "../../components/Animated";
import { useHistory } from "react-router";
import {
    BookingOrder,
    CouponType,
    NewBookingOrderItem,
    PaymentProviderType
} from "../../services/order/models";
import React, { useEffect, useState } from "react";
import { changeEventHandlerDecorator } from "../../components/libs";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import PlusMinusWidget from "../../components/plusMinusWidget";
import ModifiersModal from "../menu/components/modifiersModal";
import Overlay from "../menu/components/overlay";
import OrderingNotAvailable from "../orderingNotAvailable";
import { Spinner } from "../../components/spinner";
import 'react-phone-input-2/lib/style.css'
import PaymentDetailsBlock from "./components/paymentDetails";
import PriceWithCoupon from "./components/priceWithCoupon";
import TitleList from "../../components/titles/titleList";
import { PaperBlock } from "../../components/paperBlock";
import { SimpleGreyBackgroundContainer } from "../../components/containers/simpleGreyBackgroundContainer";
import CouponBlock from "./components/couponBlock";
import sbpLogo from "./assets/sbp-logo.png"
import RestifyLoader from "../restifyLoader";

import noImage from "./assets/noImageProduct.png"

import { EventType, NewEventName } from "../../services/general/models";
import SmallGreyTitle from "../../components/titles/SmallGreyTitle";
import BonusCardBlock from "./components/bonusCardBlock";
import RestifyFooter from "../../components/restifyFooter";
import LazyImage from "../../components/imageLazyLoader";
import moment from "moment";
import { Moment } from "moment-timezone";


const BookingCartPage = () => {

    const { cartStore, bookingMenuStore, bookingCheckoutStore, generalStore, generalMenuStore, ordersStore, isInited } = useStores();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSBP, setIsLoadingSBP] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const { i18n, t } = useTranslation(["general", "cart", "coupon", "menu"]);
    const locale = i18n.language;

    const coupon = bookingCheckoutStore.coupon;
    const payments = usePayments();

    useEffect(() => {
        if (!isInited) return;
        bookingMenuStore.init({
            bookingMenuId: generalStore.menuId,
            organizationBranchId: generalStore.organizationBranchId,
            organizationId: generalStore.organizationId

        }).then(async () => {
            setIsReady(true);
            const params = {
                metainfo: null,
                name: NewEventName.cart,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params);
        });


    }, [isInited])


    const showBonusCardModal = () => {
        bookingCheckoutStore.openBonusCardModal(() => {
            bookingCheckoutStore.closeBonusCardModal()
        });
    };

    const reset = () => {
        bookingCheckoutStore.reset();
        cartStore.reset();
        ordersStore.forceReload();
        generalStore.resetOrderSessionId();
    }



    if (!generalStore.isMenuAvailable) {
        return <OrderingNotAvailable/>
    }



    const items = cartStore.bookingList.reduce((memo, obj) => {
        let res = obj.items.map((item, index) => {
            return {
                item: item.menuItem,
                chosenMenuItemModifierSets: item.chosenMenuItemModifierSets,
                itemsCount: item.count,
                menuId: item.menuItem.menuId,
                itemId: item.menuItem.id,
                index,
                chosenBookingSlots: item.chosenBookingSlots
            }
        });
        memo.push(...res);
        return memo;
    }, [] as (NewBookingOrderItem & { item: MenuBookingItem | MenuItem, index: number, chosenBookingSlots: { fromDate: Moment, toDate: Moment }[] })[]);


    if (items.length === 0) {
        let link = ``;
        if (generalStore.alias !== null) {
            link = `/${generalStore.alias}?tableId=${generalStore.tableId}`
        } else {
            link = `/?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}&tableId=${generalStore.tableId}`
        }
        history.push(link);
    }


    const getContent = () => {

        if (!isReady || !isInited) return null;
        const paymentSettings = bookingMenuStore.bookingMenu.data.paymentSettings;


        return <>
            <div className={style.header}>
                <div className={style.back}><BackButton/></div>
                <TitleList>
                    <>{t("cart:title")}</>
                </TitleList>
            </div>
            {!generalStore.viewOnly && bookingMenuStore.bookingMenu.data.isPromoCodesEnabled &&
				<FadeInOutVertical>
					<PaperBlock>
						<CouponBlock/>
					</PaperBlock>
				</FadeInOutVertical>
            }

            <FadeInOutVertical>
                <PaperBlock>
                    {
                        items.map(({
                                       chosenBookingSlots,
                                       item,
                                       itemsCount,
                                       chosenMenuItemModifierSets,
                                       index
                                   }, indexNew) => {
                            const slots = [...chosenBookingSlots].sort((slot1, slot2) => {
                                return slot1.fromDate.unix() - slot2.fromDate.unix();
                            })
                            let from = slots[0].fromDate.clone().startOf("day");
                            return <div key={`${item.id}_${indexNew}`} className={style.menuItemBox}>
                                <div className={style.item}>
                                    {bookingMenuStore.storeFrontMode === MenuItemsPhotosModes.defaultWithPhotos &&
										<div className={style.menuItemBoxPhotoBlock}>
                                            {item.data.media.length > 0 ?
                                                <LazyImage
                                                    media={item.data.media[0]}
                                                    type={MediaType.regular}
                                                    disableLazy={true}
                                                    height={'auto'}
                                                    alt=""/>
                                                :
                                                <LazyImage
                                                    media={null}
                                                    disableLazy={true}
                                                    src={noImage}
                                                    height={'auto'}
                                                    alt=""/>
                                            }

										</div>
                                    }
                                    <div style={{ flexGrow: 1 }}>
                                        <div>
                                            <p className={style.itemTitle}>
                                                {item.data.name[locale]}
                                            </p>
                                            <p className={style.itemInfo}>
                                                {chosenBookingSlots.length}  x {item.data.sellPrice.price/100} {t('general:currency.rub')}/{t('menu:bookingSlots.timeSlot')}
                                            </p>
                                            {chosenMenuItemModifierSets.map((chosenModifierSet) => {
                                                const modifierSet = bookingMenuStore.getModifiersById(chosenModifierSet.menuItemModifierSetId);
                                                return chosenModifierSet.chosenMenuItemModifiers.map((chosenModifier) => {
                                                    const modifier = modifierSet?.data.modifiers.find(
                                                        item => item.id === chosenModifier?.menuItemModifierId);
                                                    if (!modifier) return null;
                                                    return <p className={style.itemModifier}
                                                              key={chosenModifier.menuItemModifierId}>
                                                        {modifier.data.name[locale]} x {
                                                        //@ts-ignore
                                                        chosenModifier?.count || 1
                                                    }
                                                    </p>
                                                });

                                            })}

                                            {slots.map((slot, index) => {

                                                let section = null;
                                                if(from.clone().unix() !== slot.fromDate.clone().startOf('day').unix()){
                                                    from = slot.fromDate.clone().startOf('day');
                                                    section = <p className={style.itemDateSection}>{from.format("DD.MM.YYYY")}</p>
                                                }else if(index === 0) {
                                                    section = <p className={style.itemDateSection}>{from.format("DD.MM.YYYY")}</p>
                                                }

                                                return (
                                                    <>
                                                        {section}
                                                        <p className={style.itemInfo}>
                                                            {moment(slot.fromDate).format("HH:mm")} - {moment(slot.toDate).format("HH:mm")}
                                                        </p>
                                                    </>
                                                )
                                            })}

                                        </div>


                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        <PlusMinusWidget
                                            onCartPage={true}
                                            menuItem={item}
                                            index={0}
                                            menuItemsCount={itemsCount}
                                            size='small'
                                            indexToRemove={index}
                                            chosenMenuItemModifierSets={chosenMenuItemModifierSets}
                                        />
                                    </div>
                                </div>

                            </div>
                        })
                    }

                    <PriceWithCoupon/>

                    {(!coupon ||
                            ((coupon.data.type === CouponType.fixedAmount || coupon.data.type === CouponType.percentage) && new Date(coupon.data.startDate).getTime() > Date.now() || new Date(coupon.data.endDate).getTime() < Date.now())) &&
						<div className={style.horizontalRedLine}>
							<div className={style.item}>
								<div>
									<p className={style.itemTitle}>
                                        {t("cart:total")}
									</p>
								</div>
								<p className={style.itemPrice}>
                                    {(cartStore.getTotal(MenuType.booking) / 100).toLocaleString()}
									<span className={style.currency}> {t("general:currency.rub")}</span>
								</p>
							</div>
						</div>
                    }

                </PaperBlock>
            </FadeInOutVertical>

            {bookingMenuStore.bookingMenu.data.isLoyaltyProgramEnabled &&
				<PaperBlock>
					<div className={style.bonuses}>
						<div className={style.bonusText}>
							<p className={style.bonusTitle}>
                                {t("cart:bonusCard")}</p>
							<SmallGreyTitle>{t("cart:addPhone")}</SmallGreyTitle>
						</div>
						<div className={style.bonusButton}
							 onClick={showBonusCardModal}>{t("general:add")}</div>
					</div>
				</PaperBlock>}
            {!generalStore.viewOnly && <>
				<PaymentDetailsBlock/>
				<div>
					<p className={style.sectionTitle}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<span>{t("cart:comment")}</span>
						</div>
					</p>
					<PaperBlock style={{
                        marginTop: 5,
                        marginBottom: 50
                    }}>
						<div>
							<input
								className={style.deliverySettingsAddressInput}
								value={bookingCheckoutStore.comment}
								onChange={changeEventHandlerDecorator((value: string) => bookingCheckoutStore.setComment(value))}
							/>
						</div>
					</PaperBlock>
				</div>
			</>
            }
            {(paymentSettings[PaymentProviderType.sbpTochka]?.isEnabled && [PaymentProviderType.sbpTochka, PaymentProviderType.cloudpayments, PaymentProviderType.sberbank].indexOf(bookingCheckoutStore.paymentType) >= 0 && bookingCheckoutStore.isReadyToSubmit && bookingMenuStore.workingTime) &&
				<div className={style.sbpButtonContainer}>
					<div className={style.sbpButton}
						 onClick={async () => {
                             if (!bookingMenuStore.workingTime) return false;
                             if (isLoadingSBP) return false;
                             setIsLoadingSBP(true);
                             bookingCheckoutStore.setPaymentType(PaymentProviderType.sbpTochka);
                             bookingCheckoutStore.setPaymentDetails({ type: PaymentProviderType.sbpTochka });
                             const result = await bookingCheckoutStore.createBookingOrder();
                             if (!result.err && result.order.paymentDetails?.type === PaymentProviderType.sbpTochka && result.order) {
                                 const url = result.order.paymentDetails.qrCodeLink;
                                 const orderId = result.order.id;
                                 history.push(`/order/${generalStore.organizationId}/${generalStore.organizationBranchId}/${generalStore.menuId}/${orderId}/payment`)
                                 window.open(url, '_blank');
                                 setIsLoadingSBP(false);
                             } else {
                                 onFail();
                             }

                         }}>
                        {isLoadingSBP ?
                            <Spinner/>
                            :
                            <>
                                <p>{t("cart:pay")}</p>
                                <img src={sbpLogo}/>
                            </>
                        }

					</div>
				</div>
            }
            {(paymentSettings[PaymentProviderType.cloudpayments]?.isEnabled || paymentSettings[PaymentProviderType.sberbank]?.isEnabled || bookingCheckoutStore.paymentType === PaymentProviderType.offline) &&
                !generalStore.viewOnly &&
				<div className={style.buttonContainer}>
					<div
						className={[style.button, !bookingCheckoutStore.isReadyToSubmit || isLoadingSBP || !bookingMenuStore.workingTime ? style.disabled : ""].join(" ")}
						onClick={async () => {
                            if (isLoading || !bookingCheckoutStore.isReadyToSubmit || !bookingMenuStore.workingTime) return false;
                            setIsLoading(true);

                            const result = await bookingCheckoutStore.createBookingOrder();
                            if (!result.err)
                                if (!result.err && bookingCheckoutStore.paymentType === PaymentProviderType.cloudpayments) {
                                    payments.initCharge(PaymentProviderType.cloudpayments, result.order, {
                                        onSuccess, onFail
                                    });
                                } else if (!result.err && bookingCheckoutStore.paymentType === PaymentProviderType.sberbank) {
                                    payments.initCharge(PaymentProviderType.sberbank, result.order, {
                                        onSuccess, onFail
                                    });
                                } else if (!result.err) {
                                    onSuccess();
                                } else {
                                    onFail();
                                }
                            setTimeout(() => {
                                setIsLoading(false);
                            }, 2000);
                        }}>
                        {isLoading ?
                            <Spinner/>
                            :
                            (paymentSettings[PaymentProviderType.offline].isEnabled
                                && bookingCheckoutStore.paymentType === PaymentProviderType.offline) ? t("cart:confirmOrder") : t("cart:pay")
                        }
					</div>
				</div>
            }
            {(generalStore.isLogoAndLinkEnabled && generalStore.viewOnly) &&
				<RestifyFooter/>}


            <BonusCardBlock/>
        </>
    }


    const goToOrderPage = (orderId?: number) => {
        const { organizationId, organizationBranchId, menuId } = generalStore;
        if (orderId) {
            history.push(`/booking-order/${organizationId}/${organizationBranchId}/${menuId}/${orderId}/view`);
        } else {
            history.push(
                `/?orgId=${organizationId}&branchId=${organizationBranchId}&menuId=${menuId}&tableId=${generalStore.tableId}`
            );
        }
    }
    const onSuccess = async () => {
        const orderId = bookingCheckoutStore.order?.id;
        if (bookingCheckoutStore.paymentType === PaymentProviderType.toBankCard) {
            history.push(`/order/${generalStore.organizationId}/${generalStore.organizationBranchId}/${generalStore.menuId}/${orderId}/toBankCardPayment`);
        } else if (bookingCheckoutStore.paymentType === PaymentProviderType.offline) {
            goToOrderPage(orderId);
            const params = {
                metainfo: null,
                name: NewEventName.orderCreated,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params);
        } else if (bookingCheckoutStore.paymentType === PaymentProviderType.cloudpayments) {
            const params1 = {
                metainfo: null,
                name: NewEventName.orderCreated,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params1);
            const params2 = {
                metainfo: null,
                name: NewEventName.orderPaid,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params2);
            goToOrderPage(orderId);
        } else if (bookingCheckoutStore.paymentType === PaymentProviderType.sberbank) {
            const params1 = {
                metainfo: null,
                name: NewEventName.orderCreated,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params1);
            const params2 = {
                metainfo: null,
                name: NewEventName.orderPaid,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params2);
            goToOrderPage(orderId);

        } else if (bookingCheckoutStore.paymentType === PaymentProviderType.companyInvoice) {
            history.push(`/booking-order/${generalStore.organizationId}/${generalStore.organizationBranchId}/${generalStore.menuId}/${orderId}/toCompanyByInvoice`);
        }

        setTimeout(async () => {
            if (bookingCheckoutStore.order) {
                await bookingCheckoutStore.reloadOrder();
                const order: BookingOrder = {
                    ...bookingCheckoutStore.order,
                    clientId: bookingCheckoutStore.clientId,
                    loyaltyProgram: bookingCheckoutStore.loyaltyProgram,
                }
                ordersStore.addOrder(order, MenuType.booking);
                reset()
            } else {
                console.log("Ошибка добавления заказа")
            }
        }, 2000)


    };
    const onFail = () => {
        console.log("Ошибка")
    };


    return <SimpleGreyBackgroundContainer>
        <RestifyLoader
            show={!isReady || !isInited}
        />

        {isReady && isInited && getContent()}

    </SimpleGreyBackgroundContainer>
};


export default observer(BookingCartPage);
