import { action, computed, makeAutoObservable, runInAction } from "mobx"
import content from "../content.json"
import { MenuItemModifierSet } from "../../types/types";
import MenuService from "../../services/menu";
import { ExecutionStatus } from "../../apiCommandsExecutor/api";
import {
    DeliveryType,
    Menu, MenuBookingItem,
    MenuCategory,
    MenuItem, MenuItemData,
    ModifiersSetType,
    Table,
    WorkingScheduleDay
} from "../../services/menu/models";
import { rootStore } from "../index";
import moment, { Moment } from "moment";
import manifest from "./../manifest.json";
import { saveStoreToIndexedDB } from "./../persistence";


class MenuViewStore {
    activeCategory: number = 21;
    // scroll: boolean = false;
    isReady: boolean = false;
    //@ts-ignore
    menu: Menu = null;
    //@ts-ignore
    modifiersSet: { [s: string]: MenuItemModifierSet } = {};
    categories: MenuCategory[] = [];

    //@ts-ignore
    items: { [s: string]: MenuItem[] } = {};
    tables: Table[] = [];
    products = content.productsById as { [s: number]: any };

    // isModifiersPopupVisible: boolean = false;
    // isCategoryMenuVisible: boolean = false;
    // isDetailsModalVisible: boolean = false;
    isWaiterWanted:boolean = false;
    isWaiterCalled: boolean = false;
    selectedPopupItem?: MenuItem;
    // isOverlayOpen: boolean = false;
    // isLangsModalOpen: boolean = false;

    // onOverlayClick?: () => void;
    //
    //
    isLoaded: boolean = false;

    status: {
        err: boolean,
        msg: string
    } = { err: false, msg: "" };

    constructor() {
        makeAutoObservable(this);
    }



    get isWaiterCallAvailable(){
        return this.menu.data.isCallWaiterEnabled && rootStore.generalStore.tableId;
    }

    get isChooseLangsEnabled(){
        return Object.values(this.menu.data.availableLanguages).filter(t => t.isEnabled).length > 1;
    }

    async init(params: { menuId: number, organizationId: number, organizationBranchId: number }) {
        const res = await MenuService.getMenu(params);
        if (res.executionStatus === ExecutionStatus.Finished) {
            runInAction(() => {
                if(!res.menu) return;
                this.menu = res.menu;
                this.modifiersSet = res.menuItemModifierSetsById;
                this.items = Object.entries(res.menuItemsByCategoryId)
                    .map(([key, items]) => {
                        let res = items.filter(item => !item.data.isInStopList);
                        return [key, res] as [string, MenuItem[]];
                    }).reduce((memo, entry) => {
                        memo[entry[0]] = entry[1];
                        return memo;
                    }, {} as { [s: string]: MenuItem[] })
                this.activeCategory = res.menuCategories[0]?.id;
                this.categories = res.menuCategories.filter((category) => {
                    return this.items[category.id] && this.items[category.id].length > 0
                }).sort((item1, item2) => {
                    return item1.data.sortOrder - item2.data.sortOrder
                })
                this.tables = res.tables;
            });
            rootStore.generalStore.setIsMenuAvailable(
                res.menu && res.menu.data.isMenuEnabled && this.categories.length > 0
            );

            rootStore.generalStore.setIsAcceptingOrders(res.menu.data.isOrderCreationEnabled && (res.menu.data.deliverySettings[DeliveryType.toAddress]?.isEnabled ||
                res.menu.data.deliverySettings[DeliveryType.toTable]?.isEnabled ||
                res.menu.data.deliverySettings[DeliveryType.takeaway]?.isEnabled ||
                res.menu.data.deliverySettings[DeliveryType.takeawayToTime]?.isEnabled ||
                res.menu.data.deliverySettings[DeliveryType.toVehicle]?.isEnabled ||
                res.menu.data.deliverySettings[DeliveryType.insideTheRestaurant]?.isEnabled));

            rootStore.generalStore.setViewOnlyMode(!rootStore.generalStore.isAcceptingOrders);
            if(res.menu){
                rootStore.cartStore.setMenuId(res.menu.id, res.menu.type)
                rootStore.cartStore.filterCart(res.menu.type);
            }
        } else {
            rootStore.generalStore.setIsAcceptingOrders(false);
        }

        runInAction(() => this.isLoaded = true);
    }


    async callWaiter () {
        const table = this.getTableByPublicId(rootStore.generalStore.tableId);
        if(!table) return;
        const params = {
            menuId: rootStore.generalStore.menuId,
            organizationBranchId: rootStore.generalStore.organizationBranchId,
            tableId: table.id
        }
        const res = await MenuService.callWaiter(params);
        if (res.executionStatus === ExecutionStatus.Finished) {
            runInAction(() => {
                this.isWaiterCalled = true;
            });
        }
    }

    setIsWaiterCalled(value: boolean){
        this.isWaiterCalled = value;
    }

    @action
    setError(msg: string) {
        this.status.err = true;
        this.status.msg = msg;
    }

    @action
    removeError() {
        this.status.err = false;
        this.status.msg = "";
    }


    @action
    checkingScheduleHours() {
        if (!this.menu) return;
        const today = moment().add(this.menu.data.workingSchedule.timezoneInMinutes, 'minutes');
        const weekBefore = today.clone().subtract(7, 'days');

        const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        const daysMoment: {
            [s: number]: Moment
        } = [];
        let t = weekBefore.clone();
        while(t.toDate() <= today.toDate()){
            daysMoment[t.day()] = t;
            t = t.clone().add(1, 'day');
        }


        let slots: {
            from: Moment,
            to: Moment
        }[] = [];

        slots = Object.entries(this.menu.data.workingSchedule).filter(([day, item]) => {
            if(days.indexOf(day) < 0) return false;
            const schedule =  item as WorkingScheduleDay;
            return !schedule.isDayOff
        }, []).map(([day, schedule]: [string, WorkingScheduleDay]) => {
            const dayNumber = days.indexOf(day);
            const date = daysMoment[dayNumber];
            const slot = schedule.slots[0];
            let from = date.clone().hours(slot.fromHour).minutes(slot.fromMinute).add(this.menu.data.workingSchedule.timezoneInMinutes, 'minutes')
            let to = date.clone().hours(slot.toHour).minutes(slot.toMinute).add(this.menu.data.workingSchedule.timezoneInMinutes, 'minutes');
            if(slot.toHour < slot.fromHour){
                to.add(1, 'day')
            }
            return {
                from, to
            };
        })

        const result = slots.filter(slot => {
            return today.toDate() >= slot.from.toDate() && today.toDate() <= slot.to.toDate()
        }).length

        return result > 0;
}



    @computed
    get workingTime(){
        return this.checkingScheduleHours();
    }


    @action
    setIsReady(value: boolean) {
        this.isReady = value;
    }

    @action
    setIsLoaded(value: boolean) {
        this.isLoaded = value;
    }


    @action
    setActiveCategory(categoryId: number, scroll: boolean = false) {
        this.activeCategory = categoryId;
        rootStore.generalMenuStore.scroll = scroll;
    }

    // @action
    // resetScroll() {
    //     this.scroll = false;
    // }

    @action
    reset(){
        //@ts-ignore
        this.menu = null;
        this.activeCategory = -1;
        this.isLoaded = false;
        this.modifiersSet = {};
        this.categories = [];
        this.items = {};
        saveStoreToIndexedDB();
    }


    @computed
    getProductById(id: number) {
        return this.products[id]
    }

    @computed
    getModifiersById(id: number) {

        return this.modifiersSet[id.toString()]
    }

    @action
    openModifiersPopup(item: MenuItem,  onOverlayClick: () => void) {
        rootStore.generalMenuStore.isModifiersPopupVisible = true;
        rootStore.generalMenuStore.isOverlayOpen = true;
        rootStore.generalMenuStore.onOverlayClick = onOverlayClick;
        this.selectedPopupItem = item;
        document.body.style.overflow = 'hidden';
    }

    @action
    closeModifiersPopup() {
        rootStore.generalMenuStore.isModifiersPopupVisible = false;
        rootStore.generalMenuStore.isOverlayOpen = false;
        rootStore.generalMenuStore.onOverlayClick = undefined;
        this.selectedPopupItem = undefined;
        document.body.style.overflow = 'auto';
    }

    // @action
    // openCategoryMenu( onOverlayClick: () => void) {
    //     this.isCategoryMenuVisible = true;
    //     this.isOverlayOpen = true;
    //     this.onOverlayClick = onOverlayClick;
    //     document.body.style.overflow = 'hidden';
    // }

    // @action
    // closeCategoryMenu() {
    //     this.isCategoryMenuVisible = false;
    //     this.isOverlayOpen = false;
    //     this.onOverlayClick = undefined;
    //     document.body.style.overflow = 'auto';
    // }

    // @action
    // openInfoDetailesModal( onOverlayClick: () => void) {
    //     this.isDetailsModalVisible = true;
    //     this.isOverlayOpen = true;
    //     this.onOverlayClick = onOverlayClick;
    //     document.body.style.overflow = 'hidden';
    // }
    // @action
    // closeInfoDetailesModal() {
    //     this.isDetailsModalVisible = false;
    //     this.isOverlayOpen = false;
    //     this.onOverlayClick = undefined;
    //     document.body.style.overflow = 'auto';
    // }

    @action
    openWaiterWantedModal( onOverlayClick: () => void) {
        this.isWaiterWanted = true;
        rootStore.generalMenuStore.isOverlayOpen = true;
        rootStore.generalMenuStore.onOverlayClick = onOverlayClick;
        document.body.style.overflow = 'hidden';
    }
    @action
    closeWaiterWantedModal() {
        this.isWaiterWanted = false;
        rootStore.generalMenuStore.isOverlayOpen = false;
        rootStore.generalMenuStore.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    }
    // @action
    // openLangsModal( onOverlayClick: () => void) {
    //     this.isLangsModalOpen = true;
    //     this.isOverlayOpen = true;
    //     this.onOverlayClick = onOverlayClick;
    //     document.body.style.overflow = 'hidden';
    // }
    // @action
    // closeLangsModal() {
    //     this.isLangsModalOpen = false;
    //     this.isOverlayOpen = false;
    //     this.onOverlayClick = undefined;
    //     document.body.style.overflow = 'auto';
    // }

    @action
    setData(obj: any) {
        //this.activeCategory = obj.activeCategory;
        //this.categories = obj.categories;
        //this.productsByCategory = obj.productsByCategory;
    }

    @computed
    getMinPrice(item: MenuItem | MenuBookingItem) {
        let minPrice = 0;
        item.data.menuItemModifierSets.forEach((setId) => {
            const set = this.getModifiersById(setId.menuItemModifierSetId);
            let min = Number.MAX_SAFE_INTEGER;
            set.data.modifiers.forEach((item) => {
                if (item.data.sellPrice.price < min) {
                    min = item.data.sellPrice.price
                }
            })
            if(set.data.type === ModifiersSetType.ChooseOne){
                 minPrice += min;
            }else{
                let {minCountTotalChosen} = set.data;
                if(minCountTotalChosen > 0) minPrice += min;
            }
        });
        if (minPrice === Number.MAX_SAFE_INTEGER) return 0;
        return minPrice;
    }

    static fromJSON(obj: any) {
        const products = new MenuViewStore();
        products.setData(obj);
        return products;
    }


    @computed
    getTableById(id: number) {
        return this.tables.find(item => item.id === id);
    }

    @computed
    getTableByPublicId(id: string) {
        return this.tables.find(item => item.data.publicId === parseInt(id));
    }

    @computed
    get storeFrontMode(){
        return this.menu.data.themeSettings.storefrontPhotosMode;
    }

    updateManifest(alias: string, name: string, description: string, logoUrl: string | null){
        const newManifest = {
            ...manifest,
            short_name: name,
            name: name,
            start_url: `${alias}`,
            icons: logoUrl ? [
                {
                    "src": logoUrl,
                    "sizes": "391x391",
                    "type": "image/jpeg"
                }
            ] : manifest.icons
        }
        const stringManifest = JSON.stringify(newManifest);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.getElementById('my-manifest-placeholder')?.setAttribute('href', manifestURL);
        document.title = name;
        const descriptionTag = document.getElementById('description-placeholder');
        descriptionTag && (descriptionTag.innerText = description);
        if(logoUrl){
            document.getElementById('apple-touch-icon')?.setAttribute('href',  logoUrl);
        }
    }


    updateSeoInfo(params: {name: string, description: string}){
        document.title = `${params.name} - меню`;
        const descriptionTag = document.getElementById('description-placeholder');
        descriptionTag && (descriptionTag.innerText = `${params.name} - ${params.description}`);
    }


}


export default MenuViewStore
