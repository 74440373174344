import Cart from "./cart";
import Checkout from "./checkout";

import { makeAutoObservable, runInAction } from "mobx";
import { IndexedDBWrapper, loadFromIndexedDB, loadFromLocalStorage } from "./persistence";
import MenuViewStore from "./menu/menu";
import BookingMenuViewStore from "./menu/bookingMenu";
import GeneralMenuViewStore from "./menu/generalMenu";
import GeneralStore from "./general"
import OrdersStore from "./orders";
import CatalogStore from "./catalog";
import BookingCheckout from "./bookingCheckout";


export class RootStore {
    //@ts-ignore
    cartStore: Cart = new Cart()

    //@ts-ignore
    checkoutStore: Checkout = new Checkout()

    //@ts-ignore
    bookingCheckoutStore: BookingCheckout = new BookingCheckout()

    //@ts-ignore
    generalMenuStore: GeneralMenuViewStore = new GeneralMenuViewStore()

    //@ts-ignore
    menuStore: MenuViewStore = new MenuViewStore()

    //@ts-ignore
    bookingMenuStore: BookingMenuViewStore = new BookingMenuViewStore()

    //@ts-ignore
    generalStore: GeneralStore = new GeneralStore()

    //@ts-ignore
    catalogStore: CatalogStore = new CatalogStore();

    //@ts-ignore
    ordersStore: OrdersStore = new OrdersStore();

    isInited: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }


    isOkToUseSnapshot(orgId: number, orgBranchId: number, menuId: number) {
        return this.generalStore.organizationId === orgId &&
            this.generalStore.organizationBranchId === orgBranchId &&
            this.generalStore.menuId === menuId;
    }

    /*    static createNew(value: string) {
            const rootStore = new RootStore();
            let cartStore = new Cart();
            let checkoutStore = new Checkout();
            let menuViewStore = new MenuViewStore();
            let generalStore = new GeneralStore();
            let orderStore = new OrdersStore();
            let catalogStore = new CatalogStore();

            if (value && value.length > 0) {
                const json = JSON.parse(value);
                if (json.productsStore) menuViewStore = MenuViewStore.fromJSON(json.productsStore);
                if (json.cartStore) cartStore = Cart.fromJSON(json.cartStore);
                if (json.checkoutStore) checkoutStore = Checkout.fromJSON(json.checkoutStore);
                if (json.generalStore) generalStore = GeneralStore.fromJSON(json.generalStore);
            }

            rootStore.cartStore = cartStore;
            rootStore.menuStore = menuViewStore;
            rootStore.checkoutStore = checkoutStore;
            rootStore.generalStore = generalStore;
            rootStore.ordersStore = orderStore;
            rootStore.catalogStore = catalogStore;
            return rootStore;
        }*/


    init() {

        let value: string = "";
        try{
            //await IndexedDBWrapper.initialize()
            //value = await loadFromIndexedDB();
            value = loadFromLocalStorage();
        }catch (err){
            value = loadFromLocalStorage();
        }

        let cartStore = new Cart();
        let checkoutStore = new Checkout();
        let bookingCheckoutStore = new BookingCheckout();
        let menuViewStore = new MenuViewStore();
        let bookingMenuViewStore = new BookingMenuViewStore();
        let generalMenuViewStore = new GeneralMenuViewStore();
        let generalStore = new GeneralStore();
        let ordersStore = new OrdersStore();
        let catalogStore = new CatalogStore();


        if (value && value.length > 0) {
            const json = JSON.parse(value);
            if (json.menuViewStore) menuViewStore = MenuViewStore.fromJSON(json.menuViewStore);
            if (json.generalMenuStore) generalMenuViewStore = GeneralMenuViewStore.fromJSON(json.generalMenuStore);
            if (json.bookingMenuStore) bookingMenuViewStore = BookingMenuViewStore.fromJSON(json.bookingMenuStore);
            if (json.cartStore) cartStore = Cart.fromJSON(json.cartStore);
            if (json.checkoutStore) checkoutStore = Checkout.fromJSON(json.checkoutStore);
            if (json.bookingCheckoutStore) bookingCheckoutStore = BookingCheckout.fromJSON(json.bookingCheckoutStore);
            if (json.generalStore) generalStore = GeneralStore.fromJSON(json.generalStore);
            if (json.ordersStore) ordersStore =  OrdersStore.fromJSON(json.ordersStore);
        }

        runInAction(() => {
            this.cartStore = cartStore;
            this.menuStore = menuViewStore;
            this.bookingMenuStore = bookingMenuViewStore;
            this.generalMenuStore = generalMenuViewStore;
            this.checkoutStore = checkoutStore;
            this.bookingCheckoutStore = bookingCheckoutStore;
            this.generalStore = generalStore;
            this.ordersStore = ordersStore;
            this.catalogStore = catalogStore;
            this.isInited = true;
        })
    }
}


export let rootStore: RootStore = new RootStore()
rootStore.init();


